<template>
  <div class="main__box">
    <h1 class="page__ttl">フィードの追加</h1>
    <div v-if="loading">
      <Loader />
      <p>RSSフィードを探しています。</p>
    </div>
    <div v-else>
      <div class="one__block">
        <h2 class="blk__ttl">URLから追加する</h2>
        <p>{{message}}</p>
        <p class="descriptin">RSSを登録したサイトのURLを入力してください。</p>
        <p class="feed__url__ipt"><input type="text" v-model="url" /></p>
        <p class="btn"><button class="submit__btn" aria-label="add rss feed" :disabled="urlchecked" @click="addFeed">追加</button></p>        
      </div>
      <div class="one__block">
        <h2 class="blk__ttl">一覧から追加する</h2>
        <ul class="feed__list">
          <li v-for="feed in getFeed" :key="feed.id" :class="{'added': !myCollection(feed.id)}">
            <div class="feed__title">
              <p class="favicon">
                <img v-lazy="{'src': feed.icon, 'error': '/imgs/logo-gray.png', 'loading': '/imgs/logo-gray.png'}" :alt="feed.title + 'のアイコン'" width="32px" height="32px">
              </p>
              <h3>{{feed.title}}</h3>
              <p class="btn__add">
                <button class="submit__btn" aria-label="add rss feed" @click="addFeed(feed.url)" :disabled="!myCollection(feed.id)">追加</button>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Atoms/Loader.vue'
import { mapActions, mapGetters } from 'vuex';
import { uuid } from 'vue-idb'
import dayjs from 'dayjs'
import validUrl from 'valid-url'

export default {
  name: 'AddFeed',
  data() {
    return {
      url: '',
      loading: false,
      message: '',
    }
  },
  components: {
    Loader,
  },
  computed: {
    ...mapGetters([
      "getFeed", "getCollection"
    ]),
    urlchecked: function () {
      if(validUrl.isHttpsUri(this.url)){
        if(this.validURL(this.url)){
          return false
        }else{
          return true
        }
      }else{
        return true
      }
    },
  },
  methods: {
    ...mapActions([
      'collectionAdd', 'feedAdd'
    ]),
    addFeed: async function(url){
      this.loading = true
      this.message = ''

      const addUrl = (typeof url === 'string')? url :this.url;

      // feedにあるか
      const feed = await this.$db.feed.where({url: addUrl})
            .toArray()
            .then((data)=>{ return data})
      // console.log(exist.length);

      // collectionに登録
      if(feed.length > 0){
        const params =
        {
          id: uuid(),
          title: feed[0].title,
          feed_id: feed[0].id,
          url: feed[0].url,
          icon: feed[0].icon,
          rss: feed[0].rss,
          updated_at: dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
        }

        await this.collectionAdd(params).then(()=>{
          this.$router.push({ name: 'Home'})
        })
      }else{
        const apiurl = 'https://api.feedclip.net/feed/detail?url=' + this.url

        const feed = await fetch(apiurl).then(response => {return response.json()})
        if(!feed.error){
          // まずはfeedに追加
          await this.feedAdd(feed)

          // collectionに追加
          const params =
            {
              id: uuid(),
              title: feed.title,
              feed_id: feed.id,
              url: feed.url,
              icon: feed.icon,
              rss: feed.rss,
              updated_at: dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
            }
          await this.collectionAdd(params).then(()=>{
            this.$router.push({ name: 'Home'})
          })
        }else{
          this.message = '指定のサイトでRSSフィードが見つかりませんでした。'
          this.url = ''
        }
      }
      this.loading = false
    },
    validURL(str) {
      const pattern = new RegExp('^((https?:)?\\/\\/)?'+ // protocol
          '(?:\\S+(?::\\S*)?@)?' + // authentication
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locater
      if (!pattern.test(str)) {
          return false;
      } else {
          return true;
      }
    },
    myCollection(id){
      const mycol = this.getCollection.map(col => col.feed_id)
      // console.log(mycol.includes(id));

      return mycol.includes(id)? false: true;
    }

  }
}

</script>
